/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {




  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        $(window).on('load', function(){
            var $blogposts = $('.posts .article-container');
            $ht0 = 0;
            $cnt = 0;
            $blogposts.each(function(){
              $cnt++;
              var $ht = $(this).outerHeight();
              if ($ht > $ht0) {
                $ht0 = $ht;
              }
              // if ($cnt = 4) {
              //   $(this).attr('class','col-md-push-1')
              // }
            });
            $blogposts.css({
              'min-height' : $ht0 + 'px'
            });

        })
$(document).ready(function(){

  if (location.hash) {
    setTimeout(function() {
      console.log(location.hash)

      window.scrollTo(0, 0);
    }, 1);
  }

  // catch anchor tags and scroll to them 
  var url = window.location.hash;
  var hash = url;
  if(hash) {
       // $.scrollTo(elem.left, elem.top);
        $('html, body').animate({
            scrollTop: $(hash).offset().top
        }, 2000);
        return false;
  };



  $('.testimonialrow').fitVids();
  $('.videotestimonial').fitVids();



  $('.hero,.hero-internal').owlCarousel({
      loop:true,
      margin:0,
      responsiveClass:true,
      nav:false,
      dots:false,
      responsive:{
          0:{
              items:1,
              nav:false,
          },
          600:{
              items:1,
              nav:false
          },
          1000:{
              items:1,
              nav:false,
              loop:false
          }
      }
      
  });

  $('.logos').owlCarousel({
      loop:true,
      margin:0,
      responsiveClass:true,
      responsive:{
          0:{
              items:2,
              nav:false
          },
          600:{
              items:3,
              nav:false
          },
          1000:{
              items:5,
              nav:false,
              loop:false
          }
      }
  });

})
$(document).ready(function() {

  


  // $('.services').each(function(){
  //   var divs = $('.col-md-4');
  //       divcount = divs.size();
  //   divs.each(function(index){

  //     if (index == 5 && !$(this).hasClass('ol-md-4 col-md-push-2') ) {
  //        $(this).addClass('col-md-4 col-md-push-2')
  //     }
  //     if (index == 6 && !$(this).hasClass() ) {
  //        $(this).addClass('col-md-4 col-md-push-2')
  //     }
  //   });
  // });




  function servicemod() {

    var ww = $(window).width();
    if (ww > 640) {

      // var servicemods = $('.services .servicemod');
      // ht1 = 0;
      // servicemods.each(function(){
      //   var smht = $(this).outerHeight();
      //   if (smht > ht1) {
      //     ht1 = smht;
      //   }
      // });
      // servicemods.css({
      //   'min-height' : ht1+10 + 'px'
      // });

    }


   
  };


  


  // setup pull image all way left & right on large screens
  function repeaterblocks() {

    var even = $('.repeaterblocks.even');
    var odd = $('.repeaterblocks.odd');
    var wndwdth = $(window).width();

    $(even).each(function(){
      if ( wndwdth > 980 ) {

          img = $(this).find('.img-responsive');
          height = $(this).outerHeight();
          content = $(this).find('.content').parent();
          content.css({

          });
          if (img.length > 0) {

            content.attr('class' , 'col-md-push-6 col-md-5 col-lg-4 col-lg-push-8 swapped');
            img.parent().parent().css({
              'position' : 'absolute',
              'left' : '-15px',
              'width' : '50vw',
              'height' : height + 'px'
            })
            img.css({
              'max-height' : height + 'px',
              'width' : 'auto',
              'left' : '0' + 'px'
            });

          }
          

          // console.log(height);

      }
    });

    $(odd).each(function(){
      if ( wndwdth > 980 ) {

          img = $(this).find('.img-responsive');
          height = $(this).outerHeight();
          content = $(this).find('.content').parent();
          content.css({

          });

          if (img.length > 0) {
            content.attr('class' , 'col-md-push-0 col-md-5 col-lg-4 swapped');
            img.parent().parent().css({
              'position' : 'absolute',
              'right' : '0px',
              'width' : '50vw',
              'height' : height + 'px'
            })
            // imgpos = img.position();
            // imglft = imgpos.left - 30;
           
            img.css({
              'max-height' : height + 'px',
              'width' : 'auto',
              'right' : '0' + 'px'
            });
          }
          

          // console.log(img);

      }
    });

  }

  repeaterblocks();
  servicemod();

  $(window).on('resize', function(){
    repeaterblocks();
    servicemod()
  });



//   $(window).scroll(function(e){ 
//     var $el = $('#menu-item-17'); 
//     var $navpoints = $('#menu-main'); 
//     var offsetstick = $el.offset();
//     var offsetnav = $navpoints.offset();
//     var sticklftcta = offsetstick.left-12;
//     var sticklftnav = offsetnav.left;
// // console.log("span is at " + offset.left + "," + offset.top + " of document");
//     var isPositionFixed = ($el.css('position') == 'fixed');
//     if ($(this).scrollTop() > 26 && !isPositionFixed){ 
//       $el.css({'position': 'fixed', 'top': '-28px', 'left' : sticklftcta + 'px'}); 
//       $navpoints.children('li').not($el).children('a').css({'position': 'relative', 'top': '-30px', 'left' : sticklftnav + 'px'});
//     }
//     if ($(this).scrollTop() < 26 && isPositionFixed){
//       $el.css({'position': 'static', 'top': '0px'});
//       $navpoints.children('li').not($el).children('a').css({'position': 'relative', 'top': 'unset', 'left' : 'unset'});
//     } 
//   });



  /*Scrolling*/
// $(window).scroll(function(){
//     var $maxScroll=900;
//     var $maxScale=.2;
//     var $x=$(window).scrollTop()/6000+1;
//     console.log("scrollTop : " + $(window).scrollTop() + "- x : " + $x);
//     if($(window).scrollTop()>$maxScroll) $x=$maxScale;
// //    $('#first .background').css({transform: 'scale('+$x+','+$x+')'});

//         $('.banner').css({transform: 'scale('+$x+','+$x+')'});
//         // $('.banner > *').css({transform: 'scale(0,0)'});

// });
  
 

  // odd.each(function(){
  //   if ( wndwdth > 980 ) {

  //       $oddimg = $(this).find('img');
  //       var rt = ($(window).width() - ($oddimg.offset().left + $oddimg.outerWidth()));
  //       // imgpos = oddimg.position();
  //       // imglft = imgpos.left - 30;
  //       height = $(this).outerHeight() + 160;
  //       $oddimg.css({
  //         'height' : height + 'px',
  //         'width' : 'auto',
  //         'right' : imglft + 'px'
  //       });

  //   }
  
  // });


  // service page inline toggles
  var toggle = $('.toggle-content').parent('li');
  toggle.each(function(){
    click = $(this).find('a');
    click.on('click', function (){
      $(this).toggleClass('open').next('.toggle-content').toggle(230);
      return false;
    });
  });


  // hamburger navigation
  $(".hamburger").click(function() {

     $(this).toggleClass("is-active");
     $('#mobile_nav').toggleClass('open');
     $('.herobanner').toggleClass('open');
     $('.logowrap').toggleClass('open');
   
  });


  // footer companies
  $('.companylogos').each(function(){
    var logo = $(this)//.find('img');
    var profile = $(this).find('.company-profile');
    // profile.hide();
    logo.hover(function(){
      // alert(logo)
      // $(this).toggleClass('open');
      profile.toggleClass('open');
    });

  });


  //parallax scroll
  $(window).on("load scroll", function() {
    var containers = $('.container-fluid');
    var parallaxElement = containers.not(".fixbottom").find(".image-wrap"),
      parallaxQuantity = parallaxElement.length;
      window.requestAnimationFrame(function() {
      for (var i = 0; i < parallaxQuantity; i++) {
        var currentElement = parallaxElement.eq(i),
          windowTop = $(window).scrollTop(),
          elementTop = currentElement.offset().top,
          elementHeight = currentElement.height(),
          viewPortHeight = window.innerHeight * 0.5 - elementHeight * 0.5,
          scrolled = windowTop - elementTop + viewPortHeight;
        currentElement.css({
          transform: "translate3d(0," + scrolled * -0.09 + "px, 0)"
        });
      }
    });
  });
  $(window).on("load scroll", function() {
    var parallaxElement = $(".content"),
      parallaxQuantity = parallaxElement.length;
      window.requestAnimationFrame(function() {
      for (var i = 0; i < parallaxQuantity; i++) {
        var currentElement = parallaxElement.eq(i),
          windowTop = $(window).scrollTop(),
          elementTop = currentElement.offset().top,
          elementHeight = currentElement.height(),
          viewPortHeight = window.innerHeight * 0.5 - elementHeight * 0.5,
          scrolled = windowTop - elementTop + viewPortHeight;
        currentElement.css({
          transform: "translate3d(0," + scrolled * +0.09 + "px, 0)"
        });
      }
    });
  });



  // fade effects and colour transitions
  $(window).on("load",function() {
    function fade() {
        var animation_height = $(window).innerHeight() * 0.25;
        var ratio = Math.round( (1 / animation_height) * 10000 ) / 10000;

        var items = $('.img-responsive,.container-fluid,.post-content');

        if (items.hasClass('.footer')) {

        } else {

          items.each(function() {

            var objectTop = $(this).offset().top;
            var windowBottom = $(window).scrollTop() + $(window).innerHeight();

            if ( objectTop < windowBottom ) {
                if ( objectTop < windowBottom - animation_height ) {
                    // $(this).html( 'fully visible' );
                    $(this).css( {
                        transition: 'opacity 1.1s linear',
                        opacity: 1
                    } );

                } else {
                    // $(this).html( 'fading in/out' );
                    $(this).css( {
                        transition: 'opacity 1.25s linear',
                        opacity: (windowBottom - objectTop) * ratio
                    } );
                }
            } else {
                // $(this).html( 'not visible' );
                $(this).css( 'opacity', 0 );
            }

          });

        }

       


    }
    $('.fadeinout').css( 'opacity', 0 );
    fade();
    $(window).scroll(function() {fade();});
});



$('.dynamic a').each(function(){
  var icon = $(this).text().replace(' ', '').toLowerCase();
  icon.trim();
  $(this).addClass(icon);
})







}); // end window load ready scrolling




$(window).on("scroll", function(){
  if($(window).scrollTop() + $(window).height() - 100 >= $(".container-fluid").offset().top){
    // alert("On viewport");

    $(this).toggleClass('odd').toggleClass('even')

    // var bgcolor = $(this).css('background');
    // console.log(bgcolor);

    // console.log($(this).html())
  }
});






        // Source credit: http://thenewcode.com/279/Rotate-Elements-on-Scroll-with-JavaScript

        // var leftItem = document.getElementById('item0'),
        //     rightItem = document.getElementById('item1');

        //     ;(function(){

        //       var throttle = function(type, name, obj){
        //         var obj = obj || window;
        //         var running = false;
        //         var func = function(){
        //           if (running){ return; }
        //           running = true;
        //           requestAnimationFrame(function(){
        //             obj.dispatchEvent(new CustomEvent(name));
        //             running = false;
        //           });
        //         };
        //         obj.addEventListener(type, func);
        //       };
              
        //       throttle("scroll", "optimizedScroll");
        //     })();

        //     window.addEventListener("optimizedScroll", function(){
              
        //       leftItem.style.transform = "rotate(-" + window.pageYOffset + "deg)";
        //       rightItem.style.transform = "rotate(" + window.pageYOffset + "deg)";
        //     })







      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
     
        






      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page only
        //alert (' in home only ');
    

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.






/*jshint browser:true */
/*!
* FitVids 1.1
*
* Copyright 2013, Chris Coyier - http://css-tricks.com + Dave Rupert - http://daverupert.com
* Credit to Thierry Koblentz - http://www.alistapart.com/articles/creating-intrinsic-ratios-for-video/
* Released under the WTFPL license - http://sam.zoy.org/wtfpl/
*
*/

;(function( $ ){

  'use strict';

  $.fn.fitVids = function( options ) {
    var settings = {
      customSelector: null,
      ignore: null
    };

    if(!document.getElementById('fit-vids-style')) {
      // appendStyles: https://github.com/toddmotto/fluidvids/blob/master/dist/fluidvids.js
      var head = document.head || document.getElementsByTagName('head')[0];
      var css = '.fluid-width-video-wrapper{width:100%;position:relative;padding:0;}.fluid-width-video-wrapper iframe,.fluid-width-video-wrapper object,.fluid-width-video-wrapper embed {position:absolute;top:0;left:0;width:100%;height:100%;}';
      var div = document.createElement("div");
      div.innerHTML = '<p>x</p><style id="fit-vids-style">' + css + '</style>';
      head.appendChild(div.childNodes[1]);
    }

    if ( options ) {
      $.extend( settings, options );
    }

    return this.each(function(){
      var selectors = [
        'iframe[src*="player.vimeo.com"]',
        'iframe[src*="youtube.com"]',
        'iframe[src*="youtube-nocookie.com"]',
        'iframe[src*="kickstarter.com"][src*="video.html"]',
        'object',
        'embed'
      ];

      if (settings.customSelector) {
        selectors.push(settings.customSelector);
      }

      var ignoreList = '.fitvidsignore';

      if(settings.ignore) {
        ignoreList = ignoreList + ', ' + settings.ignore;
      }

      var $allVideos = $(this).find(selectors.join(','));
      $allVideos = $allVideos.not('object object'); // SwfObj conflict patch
      $allVideos = $allVideos.not(ignoreList); // Disable FitVids on this video.

      $allVideos.each(function(){
        var $this = $(this);
        if($this.parents(ignoreList).length > 0) {
          return; // Disable FitVids on this video.
        }
        if (this.tagName.toLowerCase() === 'embed' && $this.parent('object').length || $this.parent('.fluid-width-video-wrapper').length) { return; }
        if ((!$this.css('height') && !$this.css('width')) && (isNaN($this.attr('height')) || isNaN($this.attr('width'))))
        {
          $this.attr('height', 9);
          $this.attr('width', 16);
        }
        var height = ( this.tagName.toLowerCase() === 'object' || ($this.attr('height') && !isNaN(parseInt($this.attr('height'), 10))) ) ? parseInt($this.attr('height'), 10) : $this.height(),
            width = !isNaN(parseInt($this.attr('width'), 10)) ? parseInt($this.attr('width'), 10) : $this.width(),
            aspectRatio = height / width;
        if(!$this.attr('name')){
          var videoName = 'fitvid' + $.fn.fitVids._count;
          $this.attr('name', videoName);
          $.fn.fitVids._count++;
        }
        $this.wrap('<div class="fluid-width-video-wrapper"></div>').parent('.fluid-width-video-wrapper').css('padding-top', (aspectRatio * 100)+'%');
        $this.removeAttr('height').removeAttr('width');
      });
    });
  };
  
  // Internal counter for unique video names.
  $.fn.fitVids._count = 0;
  
// Works with either jQuery or Zepto
})( window.jQuery || window.Zepto );

